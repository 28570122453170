exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-all-posts-page-js": () => import("./../../../src/templates/all_posts_page.js" /* webpackChunkName: "component---src-templates-all-posts-page-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/Page_template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-post-page-js": () => import("./../../../src/templates/post_page.js" /* webpackChunkName: "component---src-templates-post-page-js" */),
  "slice---src-components-footer-js": () => import("./../../../src/components/footer.js" /* webpackChunkName: "slice---src-components-footer-js" */),
  "slice---src-components-header-js": () => import("./../../../src/components/header.js" /* webpackChunkName: "slice---src-components-header-js" */)
}

